<template>
  <span :title="formatBatteryPercentage">
    <i
      :class="['fad', formatBatteryIcon]"
      :style="{
        '--fa-primary-color': primaryColor,
        '--fa-secondary-color': secondaryColor,
      }"
    ></i>
  </span>
</template>

<script>
import clamp from 'lodash/clamp';
import isNil from 'lodash/isNil';

export default {
  name: 'BatteryLevel',
  props: { lastConnect: Object },
  computed: {
    primaryColor() {
      const lvl = parseFloat(this.lastConnect?.battery);

      if (isNaN(lvl)) {
        return 'slategray';
      }

      if (lvl <= 0.2) {
        return 'red';
      }

      if (lvl <= 0.3) {
        return 'orange';
      }

      return 'limegreen';
    },
    secondaryColor() {
      return 'dimgray';
    },
    formatBatteryIcon() {
      const lvl = this.lastConnect?.battery;

      if (isNil(lvl)) {
        return 'fa-battery-slash';
      }

      if (lvl <= 0.25) {
        return 'fa-battery-quarter';
      }

      if (lvl <= 0.5) {
        return 'fa-battery-half';
      }

      if (lvl <= 0.75) {
        return 'fa-battery-three-quarters';
      }

      return 'fa-battery-full';
    },
    batteryPercentage() {
      const lvl = this.lastConnect?.battery;

      if (!lvl) {
        return undefined;
      }

      return parseInt(clamp(lvl * 100, 0, 100).toFixed(0));
    },
    formatBatteryPercentage() {
      if (isNil(this.batteryPercentage)) {
        return 'Unknown';
      }

      return `${this.batteryPercentage}%`;
    },
  },
};
</script>
