<template>
  <div class="card">
    <div class="dimmer" :class="{ active: loading }">
      <div class="loader"></div>
      <div class="dimmer-content text-center">
        <router-link
          :to="{
            name: 'kegbit_edit',
            params: { id: device.id },
          }"
          class="image"
        >
          <img
            ref="device_image"
            :src="device.dithered ? device.dithered.png : ''"
            class="tap-preview m-2"
            @load="handleLoad"
            @error="handleError"
          />
        </router-link>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center mt-auto">
        <div>
          <router-link
            :to="{
              name: 'kegbit_edit',
              params: { id: device.id },
            }"
            class="text-default"
            >{{ device.name }}
          </router-link>
          <small v-if="formatLastSeen(device)" class="d-block text-muted">
            <timeago :datetime="formatLastSeen(device)" :auto-update="true" />
          </small>
        </div>
        <div class="ml-auto">
          <router-link
            :to="{
              name: 'kegbit_stats',
              params: { id: device.id },
            }"
          >
            <battery-level :last-connect="device.lastConnect" />
          </router-link>
          <wifi-strength :last-connect="device.lastConnect" class="ml-2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WifiStrength from '@components/WiFiStrength';
import BatteryLevel from '@components/BatteryLevel';

export default {
  name: 'KegBitTapCard',
  components: { WifiStrength, BatteryLevel },
  props: { device: Object },
  data: () => ({
    url: process.env.VUE_APP_API_URL,
    untappdClientId: process.env.VUE_APP_UNTAPPD_CLIENTID,
    untappdCallbackUrl: escape(process.env.VUE_APP_UNTAPPD_CALLBACK),
    firebaseImageBaseUrl: process.env.VUE_APP_FB_IMAGE_BASE_URL,
    loading: true,
  }),
  methods: {
    handleLoad() {
      // console.log('loaded', this.$refs.device_image);
      // if (
      //   typeof this.$refs[img] !== 'undefined' &&
      //   this.$refs[img].length > 0
      // ) {
      //   this.$refs[img][0].classList.remove('active');
      // }
      this.loading = false;
    },
    handleError() {
      this.$refs.device_image.src = '/static/svg/kegbit-pint-color.svg';
      this.loading = false;
    },
    deviceImage(device) {
      return `${this.firebaseImageBaseUrl}/devices/${device.id}/${
        device.id
      }_dithered.png?rand=${this.randy()}`;
    },
    randy() {
      return Math.floor(Math.random() * 1000000);
    },
    formatLastSeen(device) {
      return device.lastConnect?.timestamp || device.updateTimestamp;
    },
  },
};
</script>
<style scoped>
.tap-preview {
  height: 264px;
  width: 176px;
}
</style>
