var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"dimmer",class:{ active: _vm.loading }},[_c('div',{staticClass:"loader"}),_c('div',{staticClass:"dimmer-content text-center"},[_c('router-link',{staticClass:"image",attrs:{"to":{
          name: 'kegbit_edit',
          params: { id: _vm.device.id },
        }}},[_c('img',{ref:"device_image",staticClass:"tap-preview m-2",attrs:{"src":_vm.device.dithered ? _vm.device.dithered.png : ''},on:{"load":_vm.handleLoad,"error":_vm.handleError}})])],1)]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"d-flex align-items-center mt-auto"},[_c('div',[_c('router-link',{staticClass:"text-default",attrs:{"to":{
            name: 'kegbit_edit',
            params: { id: _vm.device.id },
          }}},[_vm._v(_vm._s(_vm.device.name)+" ")]),(_vm.formatLastSeen(_vm.device))?_c('small',{staticClass:"d-block text-muted"},[_c('timeago',{attrs:{"datetime":_vm.formatLastSeen(_vm.device),"auto-update":true}})],1):_vm._e()],1),_c('div',{staticClass:"ml-auto"},[_c('router-link',{attrs:{"to":{
            name: 'kegbit_stats',
            params: { id: _vm.device.id },
          }}},[_c('battery-level',{attrs:{"last-connect":_vm.device.lastConnect}})],1),_c('wifi-strength',{staticClass:"ml-2",attrs:{"last-connect":_vm.device.lastConnect}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }