<template>
  <span :title="formatWifiPercentage">
    <i :class="['fad', formatWifiIcon, 'mr-1']"></i>
  </span>
</template>

<script>
import clamp from 'lodash/clamp';

export default {
  name: 'WiFiStrength',
  props: { lastConnect: Object },
  computed: {
    formatWifiIcon() {
      const wifiLvl = this.lastConnect?.wifi;

      if (!wifiLvl) {
        return 'fa-wifi-slash';
      }

      if (wifiLvl <= 0.33) {
        return 'fa-wifi-1';
      }

      if (wifiLvl <= 0.66) {
        return 'fa-wifi-2';
      }

      return 'fa-wifi';
    },
    formatWifiPercentage() {
      const lvl = this.lastConnect?.wifi;

      if (!lvl) {
        return 'Unknown';
      }

      return clamp(lvl * 100, 0, 100).toFixed(0) + '%';
    },
  },
};
</script>
