<template>
  <Layout>
    <div class="my-3 my-md-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-12 col-md-12">
                <!-- Large Card -->
                <div class="card card-profile d-none d-md-block">
                  <div
                    :style="{
                      backgroundImage: `url(${userProfileCoverPhotoUrl})`,
                    }"
                    class="card-header"
                  ></div>

                  <div v-if="user" class="card-body text-center">
                    <img :src="userProfileImageUrl" class="card-profile-img" />

                    <h3 class="mb-3">{{ user.username }}</h3>

                    <div v-if="user.untappd" class="text-muted">
                      <a
                        :href="`${user.untappd.untappd_url}`"
                        class="icon"
                        target="_blank"
                      >
                        <i class="fad fa-badge-check"></i>
                        {{ user.untappd.total_checkins }}
                      </a>
                      <a
                        :href="`${user.untappd.untappd_url}/beers`"
                        class="icon ml-3"
                        target="_blank"
                      >
                        <i class="fad fa-beer"></i>
                        {{ user.untappd.total_beers }}
                      </a>
                      <a
                        :href="`${user.untappd.untappd_url}/badges`"
                        class="icon ml-3"
                        target="_blank"
                      >
                        <i class="fad fa-badge"></i>
                        {{ user.untappd.total_badges }}
                      </a>
                      <a
                        :href="`${user.untappd.untappd_url}/friends`"
                        class="icon ml-3"
                        target="_blank"
                      >
                        <i class="fad fa-user-friends"></i>
                        {{ user.untappd.total_friends }}
                      </a>
                    </div>
                    <button
                      v-if="user.untappd"
                      class="btn btn-outline-primary btn-sm mt-4"
                      @click="unlinkUser"
                    >
                      <span class="fab fa-untappd"></span>
                      Unlink Untappd
                    </button>
                    <a
                      v-else
                      :href="
                        `https://untappd.com/oauth/authenticate/?client_id=${untappdClientId}&response_type=code&redirect_url=${untappdCallbackUrl}`
                      "
                      class="btn btn-primary m-2"
                    >
                      <img class="fab fa-untappd" /> Link Untappd
                    </a>
                  </div>
                  <div class="card-footer text-center">
                    <router-link
                      :to="{ name: 'claim_device' }"
                      class="btn btn-primary mr-2"
                    >
                      <i class="fas fa-plus-square"></i>
                      Link KegBit Tap
                    </router-link>

                    <a
                      href="https://forms.gle/E4oKBqVpP1vFsvSi7"
                      target="_blank"
                      class="btn btn-link"
                    >
                      <i
                        class="fad fa-lightbulb-exclamation"
                        style="--fa-secondary-color: gold;"
                      ></i>
                      Provide Feedback
                    </a>
                  </div>
                </div>
                <!-- Small Card -->
                <div v-if="user" class="card d-block d-md-none">
                  <div class="card-body">
                    <div class="media">
                      <span
                        class="avatar avatar-xxl mr-5"
                        :style="{
                          backgroundImage: `url(${userProfileImageUrl})`,
                        }"
                      ></span>
                      <div class="media-body  text-center">
                        <h4 class="m-0">{{ user.username }}</h4>
                        <ul
                          v-if="user.untappd"
                          class="social-links list-inline mb-0 mt-2"
                        >
                          <li class="list-inline-item">
                            <a
                              :href="`${user.untappd.untappd_url}`"
                              target="_blank"
                            >
                              <i class="fad fa-badge-check"></i>
                              {{ user.untappd.total_checkins }}
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              :href="`${user.untappd.untappd_url}/beers`"
                              target="_blank"
                            >
                              <i class="fad fa-beer"></i>
                              {{ user.untappd.total_beers }}
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              :href="`${user.untappd.untappd_url}/badges`"
                              target="_blank"
                            >
                              <i class="fad fa-badge"></i>
                              {{ user.untappd.total_badges }}
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              :href="`${user.untappd.untappd_url}/friends`"
                              target="_blank"
                            >
                              <i class="fad fa-user-friends"></i>
                              {{ user.untappd.total_friends }}
                            </a>
                          </li>
                        </ul>
                        <button
                          v-if="user.untappd"
                          class="btn btn-outline-primary btn-sm mt-4"
                          @click="unlinkUser"
                        >
                          <span class="fab fa-untappd"></span>
                          Unlink Untappd
                        </button>
                        <a
                          v-else
                          :href="
                            `https://untappd.com/oauth/authenticate/?client_id=${untappdClientId}&response_type=code&redirect_url=${untappdCallbackUrl}`
                          "
                          class="btn btn-primary mt-4"
                        >
                          <img class="fab fa-untappd" /> Link Untappd
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-center">
                    <router-link
                      :to="{ name: 'claim_device' }"
                      class="btn btn-primary mr-2"
                    >
                      <i class="fas fa-plus-square"></i>
                      Link KegBit Tap
                    </router-link>

                    <a
                      href="https://forms.gle/E4oKBqVpP1vFsvSi7"
                      target="_blank"
                      class="btn btn-link"
                    >
                      <i
                        class="fad fa-lightbulb-exclamation"
                        style="--fa-secondary-color: gold;"
                      ></i>
                      Provide Feedback
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row row-cards row-deck">
              <template v-if="loading">
                Loading...
              </template>
              <template v-else-if="devices.length == 0">
                <div class="col-12 col-md-6 col-xl-4">
                  <TapCard :device="demo"></TapCard>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="device in devices"
                  :key="device.id"
                  :class="device.deviceType"
                  class="col-12 col-md-6 col-xl-4"
                >
                  <TapCard :device="device"></TapCard>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';
import { mapState, mapGetters } from 'vuex';
import { authComputed } from '@store/helpers';
import isNil from 'lodash/isNil';
import TapCard from '@components/KegBitTapCard';

// import { db } from '@src/services/firebase/init';

window.Apex = {
  stroke: {
    width: 3,
  },
  markers: {
    size: 0,
  },
  tooltip: {
    fixed: {
      enabled: true,
    },
  },
};

export default {
  name: 'Home',
  components: {
    Layout,
    TapCard,
  },
  // props: { devices: Array },
  data: () => ({
    loading: true,
    untappdClientId: process.env.VUE_APP_UNTAPPD_CLIENTID,
    untappdCallbackUrl: escape(process.env.VUE_APP_UNTAPPD_CALLBACK),
  }),
  computed: {
    ...authComputed,
    ...mapState('user', ['devices']),
    ...mapState('untappd', ['userInfo']),
    ...mapGetters('untappd', ['isUntappdLinked']),

    userProfileImageUrl() {
      return this.user?.avatar;
    },

    userProfileCoverPhotoUrl() {
      // eslint-disable-next-line camelcase
      if (!isNil(this.user?.untappd?.user_cover_photo)) {
        return this.user.untappd.user_cover_photo;
      }
      return '/static/profile-background.jpg';
    },
  },
  mounted() {
    this.$store.dispatch('user/bindUserDevices').then(() => {
      this.loading = false;
    });
  },
  beforeDestroy() {
    this.$store.dispatch('user/unbindUserDevices');
  },
  methods: {
    unlinkUser() {
      this.$store.dispatch('untappd/unlinkAccount');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-footer {
  background-color: rgba(135, 150, 165, 0.032) !important;
}

.card-aside-column.image {
  border: 3px rgb(73, 80, 87) solid;
}

.card.card-aside {
  &.DIGITAP27BW {
    min-height: 296px;
    .card-aside-column.image {
      max-width: 176px;
    }
  }

  &.DIGITAP29BW {
    min-height: 296px;
    .card-aside-column.image {
      max-width: 176px;
    }
  }
}

.display-DIGITAP27BW {
  width: 176px;
  height: 264px;
}

.display-DIGITAP29BW {
  width: 128px;
  height: 296px;
}
</style>
